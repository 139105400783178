import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const messageService = inject(MessageService);
  return next(req).pipe(
		catchError((error: HttpErrorResponse) => {
			let errorMessage = '';

			if(error.status === 0) {
				errorMessage = `Network error (${error.statusText}): ${error.message}`;
			} else {
				if(error.status >= 400 && error.status < 500) {
					errorMessage = `Client error (${error.status}:${error.statusText}): ${error.error?.message || error.message}`;
				}
				if(error.status >= 500 && error.status < 600) {
					errorMessage = `Server error (${error.status}:${error.statusText})`;
				}
			}

			if(errorMessage)
				messageService.add({severity:'error', summary:'Request failed', detail: errorMessage});
			return throwError(() => error);
		})
	);
};


// export class HttpErrorInterceptor implements HttpInterceptor {
//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
// 	return next.handle(req).pipe(
// 	  catchError((error: HttpErrorResponse) => {
// 		let errorMessage = '';
// 		if (error.error instanceof ErrorEvent) {
// 		  // Client-side error
// 		  errorMessage = `Error: ${error.error.message}`;
// 		} else {
// 		  // Server-side error
// 		  switch (error.status) {
// 			case 401:
// 			  errorMessage = `Error 401: Unauthorized`;
// 			  break;
// 			case 403:
// 			  errorMessage = `Error 403: Forbidden`;
// 			  break;
// 			case 404:
// 			  errorMessage = `Error 404: Not Found`;
// 			  break;
// 			default:
// 			  errorMessage = `Error ${error.status}: ${error.message}`;
// 		  }
// 		}
// 		console.error(errorMessage);
// 		return throwError(errorMessage);
// 	  })
// 	);
//   }
// }