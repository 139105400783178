import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HealthService {
  constructor(private http: HttpClient) { }

  serverId() {
    return firstValueFrom(this.http.get(`${environment.app.serviceUrl}/health/id`));
  }
}