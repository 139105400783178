import { Component, inject } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { UserService } from '../../services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ButtonModule } from 'primeng/button';
import { AppService } from '../../services/app.service';
import { UserStatusComponent } from '../../components/user/user-status/user-status.component';
import { UserInterface } from 'voxr-shared';

@Component({
  selector: 'app-focus-layout',
  standalone: true,
  imports: [RouterOutlet, RouterLink, ButtonModule, UserStatusComponent],
  templateUrl: './focus-layout.component.html',
  styleUrl: './focus-layout.component.scss'
})
export class FocusLayoutComponent {
  private userService = inject(UserService);
  private app = inject(AppService);
  user?: UserInterface | null;

  constructor() {
    this.userService.user$.pipe(takeUntilDestroyed()).subscribe(user => this.user = user);
  }

}
