<p-toast></p-toast>
@if(loading) {
<div class="tw-h-screen tw-flex tw-flex-col tw-animate-pulse tw-items-center tw-justify-center tw-gap-8">
  <img src="/assets/images/logo/voxr-icon.png" alt="Voxr" class="tw-w-32 tw-h-32">
  <div class="tw-text-3xl">Loading...</div>
</div>

}
@else {
<router-outlet />
}
@if(deployment != 'PROD') {
<div [pTooltip]="serverId|json" tooltipPosition="right"
  class="tw-flex tw-items-end tw-justify-center tw-absolute tw-top-[-4rem] tw-left-[-4rem] tw-z-50 tw-w-32 tw-aspect-square -tw-rotate-45  tw-text-lg tw-bg-yellow-300 hover:tw-bg-yellow-100 tw-text-black tw-p-1 tw-cursor-pointer ">
  {{deployment}}
</div>
}