import { Component, inject } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { UserInterface } from 'voxr-shared';
import { RouterLink } from '@angular/router';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { MenuItem, MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'app-user-status',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonModule, InputTextModule, RouterLink, InputSwitchModule, MenuModule],
  templateUrl: './user-status.component.html',
  styleUrl: './user-status.component.scss'
})
export class UserStatusComponent {
  private app = inject(AppService);
  private userService = inject(UserService);

  user?: UserInterface & { _impersonated?: boolean } | null;
  name: string = ' ';
  loading: boolean = false;

  menuItems: MenuItem[] = [];

  constructor() {
    this.userService.user$.pipe(takeUntilDestroyed()).subscribe(user => this.loadUser(user));
  }

  private loadUser(user?: UserInterface | null) {
    this.user = user;
    if (this.user) {
      this.name = (this.user.profile?.['firstName'] || this.user.profile?.['name'] || this.user.email || '') +
        (this.user.profile?.['lastName'] ? ' ' + this.user.profile['lastName'] : '');

      this.menuItems = [{ label: 'My Profile', icon: 'pi pi-user', routerLink: '/profile' },]

      if (!!this.user.billing?.customerId)
        this.menuItems.push({ label: 'Account Settings', icon: 'pi pi-cog', routerLink: '/account' });
      if (this.user.roles?.length)
        this.menuItems.push({ label: 'Admin Area', icon: 'pi pi-shield', routerLink: '/admin' });

      this.menuItems.push({ label: 'Logout', icon: 'pi pi-sign-out', command: () => this.app.logout() });
    }
  }

  unimpersonate() { this.userService.unImpersonate(); }
}
