import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { UserService } from './services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '../environments/environment';
import { HealthService } from './services/health.service';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, ToastModule, TooltipModule],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  private userService = inject(UserService);
  private healthService = inject(HealthService);

  loading = true;
  deployment = environment.deployment;
  serverId?: Object;

  constructor() {
    this.userService.user$.pipe(takeUntilDestroyed()).subscribe(user => {
      // console.log('user', user);
      if (user !== undefined)
        this.loading = false;
    });
  }

  async ngOnInit() {
    this.serverId = await this.healthService.serverId();
  }


}
