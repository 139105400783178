<div class="tw-flex tw-h-screen tw-w-screen">

  <!-- sidebar -->
  <div class="uw-sidebar">
    <a class="tw-flex tw-items-center tw-justify-center tw-h-16 " routerLink="/">
      <img src="assets/images/logo/voxr-logotype.png" alt="Voxr" class="tw-h-10">
    </a>
    <div class="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto">
      <nav class="tw-flex tw-flex-col tw-gap-1 tw-flex-1 tw-py-2 ">
        @switch (area) {
        @case('admin') {
        <a routerLink="/admin/status" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> VOXR Status
        </a>
        <a routerLink="/admin/users" routerLinkActive="uw-active">
          <i class="pi pi-users"></i> Manage Users
        </a>
        @if(user?.roles?.includes('super-admin')){
        <!-- <a routerLink="/admin/workspaces" routerLinkActive="uw-active">
          <i class="pi pi-building"></i> Manage Workspaces
        </a> -->
        <a routerLink="/admin/campaigns" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> Manage Campaigns
        </a>
        <a routerLink="/admin/calls" routerLinkActive="uw-active">
          <i class="pi pi-phone"></i> Call Logs
        </a>
        }
        <a routerLink="/admin/vapi" routerLinkActive="uw-active" class="tw-mt-auto">
          <i class="pi pi-link"></i> Manage VAPI
        </a>
        @if(user?.roles?.includes('super-admin')){
        <a routerLink="/admin/plans" routerLinkActive="uw-active">
          <i class="pi pi-link"></i> Manage Plans
        </a>
        <a routerLink="/admin/integrations" routerLinkActive="uw-active">
          <i class="pi pi-link"></i> Default Integrations
        </a>
        <a routerLink="/admin/blueprints" routerLinkActive="uw-active">
          <i class="pi pi-book"></i> Default Blueprints
        </a>
        <a routerLink="/admin/campaign-templates" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> Campaigns Templates
        </a>
        <a routerLink="/admin/logs" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> System Logs
        </a>
        }

        }
        @case('account') {
        <a routerLink="/account/status" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> Account Status
        </a>
        <a routerLink="/account/phone" routerLinkActive="uw-active">
          <i class="pi pi-chart-phone"></i> Phone Settings
        </a>
        <a routerLink="/account/workspaces" routerLinkActive="uw-active">
          <i class="pi pi-chart-phone"></i> Workspaces
        </a>

        }
        @case('workspace') {
        <a routerLink="/dashboard" routerLinkActive="uw-active">
          <i class="pi pi-home"></i> Dashboard
        </a>
        <!-- <a routerLink="/contacts" routerLinkActive="uw-active">
              <i class="pi pi-users"></i> Contacts
            </a> -->
        @if(workspaceRoles?.includes('admin') || workspaceRoles?.includes('owner')) {

        @if(!workspace?.onboarded?.includes('completed')){
        <a routerLink="/onboarding" routerLinkActive="uw-active">
          <i class="pi pi-star"></i> Onboarding
        </a>
        }
        <a routerLink="/assistants" routerLinkActive="uw-active">
          <i class="pi pi-bolt"></i> Assistants
        </a>
        <a routerLink="/campaigns" routerLinkActive="uw-active">
          <i class="pi pi-chart-line"></i> Campaigns
        </a>
        <a routerLink="/calls" routerLinkActive="uw-active">
          <i class="pi pi-phone"></i> Call Logs
        </a>
        <div class="tw-flex-grow"></div>
        <a routerLink="/blueprints" routerLinkActive="uw-active">
          <i class="pi pi-book"></i> Blueprints
        </a>
        <!-- <a routerLink="/schedules" routerLinkActive="uw-active"
             >
              <i class="pi pi-calendar"></i> Schedules
            </a> -->
        <a routerLink="/integration" routerLinkActive="uw-active">
          <i class="pi pi-link"></i> Integrations
        </a>
        <a routerLink="/workspace/members" routerLinkActive="uw-active">
          <i class="pi pi-users"></i> Workspace Members
        </a>
        <a routerLink="/workspace/settings" routerLinkActive="uw-active">
          <i class="pi pi-cog"></i> Workspace Settings
        </a>
        } @else { <div class="tw-flex-grow"></div> }

        @if(workspaces?.length && workspaces!.length > 1){
        <div class="p-fluid">
          <p-dropdown [options]="workspaces" optionValue="_id" optionLabel="name" placeholder="Select Workspace"
            [ngModel]="workspace?._id" (onChange)="setWorkspace($event.value)"></p-dropdown>
        </div>
        }

        }
        }

      </nav>

    </div>
  </div>

  <!-- Main content -->
  <div class="tw-flex tw-flex-col tw-flex-1 tw-flex-grow tw-min-w-0">
    <div class="tw-flex tw-items-center tw-justify-between tw-h-16 surface-main-darker">
      @switch (area) {
      @case('workspace') {
      <div class="tw-flex tw-gap-2 tw-items-center tw-px-4">
        @if(workspace) {
        <h5>{{workspace.name}}</h5>
        @if(workspace.accountDisabled) {
        <p-tag severity="danger">Account Disabled</p-tag>
        @if(workspace.ownerId == user?._id) {
        <a routerLink="/account/onboarding" class="tw-ml-2">Activate Account</a>
        }
        }
        }
      </div>
      <app-transfer-availability />
      }
      @case('account') {
      <div>Account Settings</div>
      }
      @case('admin') {
      <div>Admin Area</div>
      }
      }
      <div class="tw-flex tw-gap-2 tw-items-center tw-pr-4">
        @if(user) {
        <app-user-status />
        } @else {
        <a routerLink="/login">Login</a>
        }
      </div>
    </div>
    <div class="tw-flex-1 tw-overflow-auto tw-pt-4 tw-px-4 " [ngClass]="{'surface-dark': area=='admin'}">
      <router-outlet />
    </div>
  </div>