import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { WorkspaceService } from '../services/workspace.service';
import { map } from 'rxjs';

export const workspaceGuard: CanActivateFn = (route, state) => {
  const workspaceService = inject(WorkspaceService);
  const router = inject(Router);
  return workspaceService.workspace$.pipe(
    map(workspace => {
      if (workspace === null) {
        router.navigate(['/select-workspace']);
        return false;
      }
      return true;
    })
  );
};
