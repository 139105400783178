@if(user) {
<div class="tw-flex tw-gap-2 tw-items-center">
  @if(user._impersonated){<p-button class="tw-cursor-pointer" label="Exit Impersonating" severity="warning" size="small"
    icon="pi pi-key" (click)="unimpersonate()"></p-button>}
  <div class="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer" (click)="userMenu.toggle($event)">
    <div
      class="tw-flex tw-items-center tw-justify-center tw-w-10 tw-aspect-square tw-rounded-full  tw-text-white tw-text-2xl tw-bg-contain tw-bg-center"
      [ngClass]="{'tw-bg-purple-700': !user.profile?.['picture']}"
      [ngStyle]="{'background-image': user.profile?.['picture'] ? 'url(' + user.profile?.['picture'] + ')' : undefined}">
      @if(!(user.profile?.['picture'])){ {{(name[0]).toUpperCase()}} }
    </div>
    <span>{{name}}</span>
  </div>
  <p-menu #userMenu appendTo="body" [model]="menuItems" [popup]="true"></p-menu>


</div>

}